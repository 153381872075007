exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-customercases-js": () => import("./../../../src/pages/customercases.js" /* webpackChunkName: "component---src-pages-customercases-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-services-dabas-js": () => import("./../../../src/pages/services/dabas.js" /* webpackChunkName: "component---src-pages-services-dabas-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-salesdistricts-js": () => import("./../../../src/pages/services/salesdistricts.js" /* webpackChunkName: "component---src-pages-services-salesdistricts-js" */),
  "component---src-pages-services-statistics-js": () => import("./../../../src/pages/services/statistics.js" /* webpackChunkName: "component---src-pages-services-statistics-js" */)
}

